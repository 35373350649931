import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import IFilterCheckbox from "../../../models/IFilterCheckbox";
import { Checkbox, CheckboxLabel, Typography, TextField, Tooltip, Box } from "@cuda-networks/bds-core";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import { EmailValidationRule2 } from "../../../fieldsValidationRules";
import { debounce } from "lodash";

interface IFilterAccountsCheckboxesProps {
  options: IFilterCheckbox[];
  userAssociatedWithThem?: string;
  userAssociatedWithThemError?: string;
  onCheckboxOptionsChange: (newCheckboxOptions: IFilterCheckbox[], index: number, userAssociatedWithEmail1?: string) => void;
  onLoginUserAssociatedWithThem?: (userAssociatedWithEmail: string) => void;
  setInitialFocus?: () => void;
  setActionInProgress?: (inProgress: boolean) => void;
}

const FilterAccountsCheckboxes: React.FC<IFilterAccountsCheckboxesProps> = ({ options, onCheckboxOptionsChange, onLoginUserAssociatedWithThem, userAssociatedWithThem, userAssociatedWithThemError, setInitialFocus, setActionInProgress }) => {
  const [userAssociatedWithEmail, setUserAssociatedWithEmail] = useState(userAssociatedWithThem);
  const [userAssociatedWithEmailError, setUserAssociatedWithEmailError] = useState("");

  const emailCheckboxRef = useRef<HTMLInputElement>(null);
  const emailTextfieldRef = useRef<HTMLInputElement>(null);

  const onToggleColumn = (id: number, userEmail?: string) => {
    const newCheckboxOptions = options.map((option: IFilterCheckbox, idx: any) => {
      if (idx === id) {
        return { ...option, checked: !option.checked };
      } else {
        return option;
      }
    });
    if (userEmail) {
      onCheckboxOptionsChange(newCheckboxOptions, id, userEmail);
    } else {
      onCheckboxOptionsChange(newCheckboxOptions, id, userAssociatedWithEmail);
    }
  };

  const uncheckUserLogin = () => {
    const option = options.find((option: IFilterCheckbox) => option.label === FilterAccountsOptions.LoginUserAssociated);
    const index = options.findIndex((option: IFilterCheckbox) => option.label === FilterAccountsOptions.LoginUserAssociated);

    if (option !== undefined) {
      if (option.checked) {
        onToggleColumn(index, "");
      }
    }
  };

  const setupEmailCheckBox = (enabled: boolean) => {
    if (emailCheckboxRef.current) {
      emailCheckboxRef.current.disabled = !enabled;
      emailCheckboxRef.current.title = enabled ? "" : "Enter a valid email to enable this filter.";

      let color = enabled ? "gray" : "lightgray";
      if (emailCheckboxRef.current.checked) {
        color = "#0088CE";
      }

      const parent = emailCheckboxRef.current.parentElement;
      if (parent) {
        const checkboxSvg = parent.querySelector("svg");
        if (checkboxSvg) {
          checkboxSvg.style.fill = color;
        }
      }
    }
  };

  const handleLoginUserAssociatedWithThem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trimStart();
    setUserAssociatedWithEmail(newValue);

    if (isValidUserAssociatedWithEmail(newValue)) {
      setupEmailCheckBox(true);
      setActionInProgress!(true);
      debouncedCustomerName(newValue);
    } else {
      setupEmailCheckBox(false);
      uncheckUserLogin();
    }
  };

  const debouncedCustomerName = useCallback(
    debounce((criteria: string) => {
      if (emailCheckboxRef.current) {
        const index = options.findIndex((option: IFilterCheckbox) => option.label === FilterAccountsOptions.LoginUserAssociated);
        if (options[index].checked) {
          onCheckboxOptionsChange(options, index, criteria);
        } else {
          onToggleColumn(index, criteria);
        }
        if (emailTextfieldRef.current) {
          emailTextfieldRef.current.focus();
        }
      }
      setActionInProgress!(false);
    }, 650),
    [options],
  );

  const isValidUserAssociatedWithEmail = (accName: string) => {
    let isValid = true;
    setUserAssociatedWithEmailError("");
    if (!accName) {
      isValid = false;
    } else if (!EmailValidationRule2.RegularExpression.test(accName)) {
      setUserAssociatedWithEmailError("Invalid email");

      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (setInitialFocus) {
      setInitialFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAssociatedWithEmail === undefined) {
      setupEmailCheckBox(false);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedCheckboxes = useMemo(() => {
    return options.map((option: IFilterCheckbox, idx: any) => (
      <div key={option.label + idx + "div"}>
        <div className={"k-column-list-item"}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {option.label !== FilterAccountsOptions.LoginUserAssociated && (
              <MemoizedCheckboxLabel
                key={option.label + idx + "label"}
                label={option.label}
                size="small"
                disabled={option.disabled}
                control={
                  <Checkbox
                    key={option.label + idx + "checkbox"}
                    data-testid="filterAccountsOption"
                    size="medium"
                    checked={option.checked}
                    //checked={validateEntry(option)}

                    onChange={() => {
                      onToggleColumn(idx);
                      console.log("onchange.");
                    }}
                  />
                }
              />
            )}
            {option.label === FilterAccountsOptions.LoginUserAssociated && (
              <MemoizedCheckboxLabel
                key={option.label + idx + "label"}
                label={option.label}
                size="small"
                disabled={option.disabled}
                control={
                  <Checkbox
                    inputRef={emailCheckboxRef}
                    key={option.label + idx + "checkbox"}
                    data-testid="filterAccountsOption"
                    size="medium"
                    checked={option.checked}
                    onChange={() => {
                      onToggleColumn(idx);
                      console.log("onchange.." + idx);
                    }}
                  />
                }
              />
            )}
            {(option.label === FilterAccountsOptions.M365LinkedOnly || option.label === FilterAccountsOptions.M365UnlinkedOnly) && (
              <Typography variant="caption" className="m365OnlyCheckboxHelperText" data-testid="m365OnlyCheckboxHelperText">
                (Results might take up to one minute)
              </Typography>
            )}
            {option.label === FilterAccountsOptions.LoginUserAssociated && (
              <Tooltip title="" placement="bottom">
                <Box display="flex" alignItems="center">
                  <TextField data-testid="searchLoginUserAssociatedWithThem" inputRef={emailTextfieldRef} error={userAssociatedWithEmailError.length > 0} style={{ backgroundColor: "white", zIndex: 300, margineRight: "2px" }} value={userAssociatedWithEmail} onChange={handleLoginUserAssociatedWithThem} />
                  <Typography data-testid="searchLoginUserAssociatedWithThemError" variant="body2" color="error">
                    &nbsp;&nbsp;{userAssociatedWithEmailError}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, userAssociatedWithEmail, userAssociatedWithThemError, onToggleColumn]);

  return (
    <div className={"k-column-list"} style={{ maxHeight: "400px" }}>
      {memoizedCheckboxes}
    </div>
  );
};

const MemoizedCheckboxLabel = React.memo(CheckboxLabel);

//export default FilterAccountsCheckboxes;
export default React.memo(FilterAccountsCheckboxes);
