import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, TextField, LinearProgress, Button } from "@cuda-networks/bds-core";
import DropDown from "../../DropDown";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import AuditUsersTable from "../AuditUsers/AuditUsersTable";
import { clearAuditStateBeforeSearch, clearSearchAuditUserResultsAction, getBilledUsersAction, searchAuditUserAction, setSearchedAuditUserEmail, setFilterByOption } from "../../../actions/auditUsersActions";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";
import { isStringNullOrEmpty } from "../../../utility";
import ImportCsv from "./ImportCsv";
import { MAX_NO_OF_BILLING_USERS_TO_IMPORT } from "../../../config";
import MspType from "../../../models/MspType";
import { fetchM365AuthAction } from "../../../actions/accountActions";
import TabTitle from "../../TabTitle";
import { AuditUserFilterByOption } from "../../../models/Products/IAuditUserDisplayState";
import useDocumentTitle, { PageTitles } from "../../../Utilities/useDocumentTitle";
import NavigateTo from "../../NavigateTo";
import { useNavigate } from "react-router";
import { useParams, useLocation } from "react-router-dom";

interface IAuditUsersCardProps {
  handleBackToProducts: () => void;
}

const AuditUsersCard: React.FC<IAuditUsersCardProps> = ({ handleBackToProducts }) => {
  const dispatch = useDispatch();
  const nextPageToken = useSelector((state: IAppState) => state.auditUsersState.nextPageToken);
  const searchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.searchedAuditUserEmail);
  const apiSearchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.apiSearchedAuditUserEmail);
  const loadingAuditUsers = useSelector((state: IAppState) => state.auditUsersState.loadingAuditUsers);
  const loadedFullList = useSelector((state: IAppState) => state.auditUsersState.loadedFullList);
  const loadingAccountM365Auth = useSelector((state: IAppState) => state.accountState.loadingAccountM365Auth);
  const auditUserStateBeforeSearch = useSelector((state: IAppState) => state.auditUsersState.auditUserStateBeforeSearch);
  const noOfActionsInProgress = useSelector((state: IAppState) => state.auditUsersState.noOfActionsInProgress);
  const subTitle = "ANY CHANGES MADE HERE MAY TAKE UP TO 48 HOURS TO TAKE EFFECT";
  const [showClearButton, setShowClearButton] = useState(false);
  const [searchedAuditUser, setSearchedAuditUser] = useState(searchedAuditUserEmail);
  const [tableMessage, setTableMessage] = useState("");
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const [table, setTable] = useState<React.JSX.Element>();
  const filterByOptionId = useSelector((state: IAppState) => state.auditUsersState.filterByOptionId);
  const filterByOps = ["Filter By: Billable User", "Filter By: Billable Licenses"];
  const filterByPlaceHolders = ["Search Billable User", "Search Billable Licenses"];
  const filterByDropDownIcon = {
    startAdornment: (
      <InputAdornment position="start">
        <CoreIcons.Filter />
      </InputAdornment>
    ),
  };

  const location = useLocation();
  const navigate = useNavigate();
  useDocumentTitle(selectedAccount, PageTitles.UserBilling);

  const { accountId } = useParams();
  const navigateTo = `/accounts/${accountId}/products-services` + window.location.search;

  const onHandleBackToProducts = (event: React.SyntheticEvent) => {
    handleBackToProducts();
    if (!isStringNullOrEmpty(auditUserStateBeforeSearch?.nextPageToken)) {
      dispatch(clearAuditStateBeforeSearch());
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      setShowClearButton(false);
      dispatch(clearAuditStateBeforeSearch());
      dispatch(getBilledUsersAction(selectedAccount, filterByOptionId));
      if (selectedAccount?.type === MspType.Customer) {
        dispatch(fetchM365AuthAction(selectedAccount));
      }
    }

    const targetPath = `/accounts/${selectedAccount?.id}/products-services/user-billing` + window.location.search;

    if (location.pathname !== targetPath) {
      console.log(` navigate to targetPath billing:  ${targetPath} != pathname:${location.pathname}`);
      navigate(targetPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount?.id]);

  useEffect(() => {
    if (!loadingAuditUsers) {
      if (searchedAuditUser && searchedAuditUser.length > 0 && apiSearchedAuditUserEmail !== searchedAuditUser) {
        if (filterByOptionId === AuditUserFilterByOption.BILLABLE_USER && loadedFullList) {
          dispatch(searchAuditUserAction(searchedAuditUser));
        } else {
          dispatch(getBilledUsersAction(selectedAccount, filterByOptionId, searchedAuditUser));
        }
        setShowClearButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAuditUsers, apiSearchedAuditUserEmail]);

  const getAuditUsersContent = (message: string): React.JSX.Element => {
    if (isStringNullOrEmpty(message)) {
      return <AuditUsersTable></AuditUsersTable>;
    } else {
      return (
        <Typography className="DarkRedColor" data-testid={"exceedsPaginationLimit"}>
          {message}
        </Typography>
      );
    }
  };

  useEffect(() => {
    setTable(getAuditUsersContent(tableMessage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableMessage]);

  const renderAuditUserClearButton = () => {
    let adornment = null;
    if (showClearButton === true) {
      adornment = (
        <InputAdornment position="end">
          <IconButton disabled={noOfActionsInProgress > 0} data-testid={"clearSearchAuditUsers"} onClick={onHandleClearSearch}>
            <CoreIcons.Close></CoreIcons.Close>
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment;
  };
  const onHandleClearSearch = () => {
    onAuditUserSearchedChange("");
    if (filterByOptionId === AuditUserFilterByOption.BILLABLE_USER) {
      onAuditUserSearched("");
    } else {
      onBillableLicenseSearch("");
    }
    dispatch(setSearchedAuditUserEmail(""));
  };

  const onSearch = () => {
    if (filterByOptionId === AuditUserFilterByOption.BILLABLE_USER) {
      onAuditUserSearched(searchedAuditUser);
    } else {
      onBillableLicenseSearch(searchedAuditUser);
    }
  };

  const onFilterByOptionChanged = (value: number) => {
    if (value > 0 && value !== filterByOptionId) {
      dispatch(setFilterByOption(value));
      onHandleClearSearch();
    }
  };

  const onAuditUserSearchedChange = (value: string) => {
    setSearchedAuditUser(value);
  };

  const onFilterKeyUp = (value: any) => {
    if (value === 13) {
      onSearch();
    }
  };

  const onAuditUserSearched = (value: string) => {
    const searchedValue = value.trim();
    dispatch(setSearchedAuditUserEmail(searchedValue));
    if (searchedValue.length > 0) {
      if (loadedFullList) {
        dispatch(searchAuditUserAction(searchedValue));
        setShowClearButton(true);
      } else {
        dispatch(getBilledUsersAction(selectedAccount, searchedValue));
        setShowClearButton(true);
      }
    } else {
      setShowClearButton(false);
      if (loadedFullList) {
        dispatch(clearSearchAuditUserResultsAction());
      } else {
        dispatch(getBilledUsersAction(selectedAccount));
      }
    }
  };

  const onBillableLicenseSearch = (value: string) => {
    const searchedValue = value.trim();
    dispatch(setSearchedAuditUserEmail(searchedValue));
    if (searchedValue.length > 0) {
      dispatch(getBilledUsersAction(selectedAccount, AuditUserFilterByOption.PRODUCT_LICENSES, searchedValue));
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
      dispatch(getBilledUsersAction(selectedAccount, AuditUserFilterByOption.PRODUCT_LICENSES));
    }
  };

  useEffect(() => {
    setSearchedAuditUser(searchedAuditUserEmail);
    if (!isStringNullOrEmpty(searchedAuditUserEmail)) {
      setShowClearButton(true);
    }
  }, [searchedAuditUserEmail]);

  useEffect(() => {
    if (selectedAccount?.m365AuthLinked !== 1 && nextPageToken !== undefined) {
      let exceedsLimitMessage = "Protected User list is too large! Please filter Protected Users to refine the list.";
      if (nextPageToken.length > 0 && searchedAuditUser.length === 0) {
        setTableMessage(exceedsLimitMessage);
      } else if (nextPageToken.length > 0 && searchedAuditUser.length > 0) {
        setTableMessage(exceedsLimitMessage);
      } else {
        setTableMessage("");
      }
    } else {
      setTableMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageToken, searchedAuditUser, selectedAccount]);

  return (
    <Card className={"AuditCard"}>
      <CardContent>
        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <TabTitle tabType={undefined} item={selectedAccount} />

            <Grid container spacing={1} item xs={12} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Typography data-testid="auditUsersListCardSubtitle" variant="subtitle1">
                {subTitle}
              </Typography>
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <NavigateTo to={navigateTo} text={"Products & Services"} callback={onHandleBackToProducts} testId="auditUsersListCardLink" />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="row">
            {!loadingAccountM365Auth && (
              <Grid className={"auditSearch"} item container xs={9} style={{ paddingBottom: "7px" }}>
                <Grid item xs={4}>
                  {selectedAccount?.m365AuthLinked === 1 && (
                    <div data-testid="auditUsersFilterByDropDown" style={{ marginBottom: "10px" }}>
                      <DropDown className={"AuditUserCardFilterLabel"} label="" required={false} options={filterByOps} error={""} selectedOptionId={filterByOptionId} handleChange={onFilterByOptionChanged} disabled={false} inputProps={filterByDropDownIcon} size={"large"} />
                    </div>
                  )}

                  {selectedAccount?.m365AuthLinked !== 1 && (
                    <Typography data-testid={"auditUsersFilterByLabel"} className={"AuditUserCardFilterLabel"}>
                      <div className={"AuditUserCardFilterLabelIcon"}>
                        <CoreIcons.Filter />
                      </div>
                      {filterByOps[0]}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Grid container direction="row">
                    <Grid item xs={10}>
                      <TextField
                        data-testid="searchAuditUserTxt"
                        fullWidth
                        InputProps={{
                          endAdornment: renderAuditUserClearButton(),
                        }}
                        placeholder={filterByPlaceHolders[filterByOptionId - 1]}
                        size={"large"}
                        value={searchedAuditUser}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAuditUserSearchedChange(ev.target.value)}
                        disabled={noOfActionsInProgress > 0}
                        className="AuditUserCardSearchButton"
                        onKeyUp={(event: any) => onFilterKeyUp(event.keyCode)}
                      />
                    </Grid>
                    <Grid item>
                      <Button data-testid={"auditUsersSearchBtn"} size={"large"} onClick={onSearch}>
                        <CoreIcons.Search></CoreIcons.Search>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={9} data-testid="auditUsersListTable">
              {noOfActionsInProgress > 0 && (
                <div style={{ display: "none" }} data-testid={"auditUsersActionInProgress"}>
                  <LinearProgress />
                </div>
              )}
              {loadingAuditUsers || loadingAccountM365Auth ? (
                <div data-testid={"loadingAuditUsersTab"}>
                  <LinearProgress />
                </div>
              ) : (
                table
              )}
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="space-around" direction="column">
                <Grid item>
                  <ImportCsv />
                </Grid>
                <Grid item>
                  <Typography>Entries are case sensitive</Typography>
                </Grid>
                <Grid item>
                  <Typography>Limit: {MAX_NO_OF_BILLING_USERS_TO_IMPORT} entries maximum in .CSV</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AuditUsersCard;
