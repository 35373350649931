import { IAccountFilters } from "../../../models/IAccountFilters";
import IAccountOrders from "../../../models/Products/IAccountOrders";
import { eliminateDuplicatesFromArray } from "../../../utility";
import { FilterAccountsUrlOptions } from "./UrlFilterParams";

export class UrlFilterExtractor {
  private url: string;
  private orders: IAccountOrders[];

  constructor(url: string, orders: IAccountOrders[]) {
    this.url = url;
    this.orders = orders;
  }

  extractFilters(): IAccountFilters {
    const queryParams = new URLSearchParams(decodeURIComponent(this.url));
    const filtersFromUrl: IAccountFilters = {};
    if (queryParams.get(FilterAccountsUrlOptions.allProducts) === "1") {
      filtersFromUrl.allProducts = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.hasErrors) === "1") {
      filtersFromUrl.hasErrors = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.noProducts) === "1") {
      filtersFromUrl.noProducts = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.onlyM365Linked) === "1") {
      filtersFromUrl.onlyM365Linked = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.onlyM365Unlinked) === "1") {
      filtersFromUrl.onlyM365Unlinked = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.hasSmbLogins) === "1") {
      filtersFromUrl.hasSmbLogins = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.hasIbu) === "1") {
      filtersFromUrl.hasIbu = true;
    }

    if (queryParams.get(FilterAccountsUrlOptions.associatedLogin)) {
      filtersFromUrl.associatedLogin = queryParams.get(FilterAccountsUrlOptions.associatedLogin)?.toString();
    }

    if (queryParams.get(FilterAccountsUrlOptions.accountName) !== undefined && queryParams.get(FilterAccountsUrlOptions.accountName) !== null && queryParams.get(FilterAccountsUrlOptions.accountName) !== "") {
      filtersFromUrl.name = queryParams.get(FilterAccountsUrlOptions.accountName) + "";
    }

    const csSkus = queryParams.get(FilterAccountsUrlOptions.csProdSkus);
    if (csSkus && csSkus !== null && csSkus !== undefined) {
      const csSkusItems = csSkus.split(",");
      const uniqueSkus = eliminateDuplicatesFromArray(csSkusItems);
      filtersFromUrl.csProdSkus = uniqueSkus.join(",");
    }

    const bsSkus = queryParams.get(FilterAccountsUrlOptions.bbsProdSkus);
    if (bsSkus && bsSkus !== null && bsSkus !== undefined) {
      const bbsSkusItems = bsSkus.split(",");
      const uniqueSkus = eliminateDuplicatesFromArray(bbsSkusItems);
      filtersFromUrl.bbsProdSkus = uniqueSkus.join(",");
    }

    if (queryParams.get(FilterAccountsUrlOptions.essSkus)) {
      const essSkus = queryParams.get(FilterAccountsUrlOptions.essSkus)?.split(",");
      console.log("essSkus", essSkus);
      if (essSkus !== undefined && essSkus?.length > 0) {
        filtersFromUrl.essSkus = [];
        const uniqueSkus = eliminateDuplicatesFromArray(essSkus);
        uniqueSkus?.forEach(essSku => {
          for (const order of this.orders) {
            const item = order?.orders.find(item => item.bundleSku === essSku);
            if (item) {
              const label = item.bundleName;
              filtersFromUrl.essSkus?.push({ sku: essSku, label: label + "" });
              break;
            }
          }
        });
      }
    }

    const accountsOf = queryParams.get(FilterAccountsUrlOptions.accountsOf);
    if (accountsOf && accountsOf !== null && accountsOf !== undefined) {
      filtersFromUrl.accountsOf = accountsOf;
    }

    return filtersFromUrl;
  }
}
