import IAccount from "../models/IAccount";
import { FilterAccountsUrlOptions } from "../components/Accounts/FilterAccounts/UrlFilterParams";
import { EmailValidationRule } from "../fieldsValidationRules";
import { UrlFilterExtractor } from "../components/Accounts/FilterAccounts/UrlFilterExtractor";
import { IAccountFilters } from "../models/IAccountFilters";
import MspType from "../models/MspType";
import IAccountOrders from "../models/Products/IAccountOrders";
import IOrderSlim from "../models/Products/IOrderSlim";
import ProductFamily from "../models/Products/ProductFamily";
import { computeProdSkuFilterOptions } from "../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";

export function validateFilters(accountId: string | undefined, url: string, mspAccountLoggedIn: IAccount, accountsNames: IAccount[], accountsOrders: IAccountOrders[]): boolean {
  const existingFilters = decodeURIComponent(url)
    .substring(1)
    .split("&")
    .filter(filter => filter !== "");
  if (existingFilters.length > 0) {
    let selectedAccount: IAccount | undefined = undefined;
    if (accountId !== undefined) {
      let allAccounts: IAccount[] = [];
      if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
        allAccounts = mspAccountLoggedIn.accounts !== undefined ? [mspAccountLoggedIn, ...mspAccountLoggedIn.accounts] : [mspAccountLoggedIn];
      } else {
        allAccounts = [mspAccountLoggedIn, ...accountsNames];
      }

      const index = allAccounts.findIndex((x: IAccount) => x.id === Number(accountId));
      if (index < 0) {
        return false;
      }
      selectedAccount = allAccounts[index];
    }
    switch (mspAccountLoggedIn.type) {
      case MspType.Customer:
        return false;
      case MspType.BillingAggregator:
        return validateFiltersForBa(existingFilters);
      case MspType.Partner:
      case MspType.Subpartner:
        if (selectedAccount !== undefined && selectedAccount.type !== MspType.Customer) {
          return false;
        }
        return validateFiltersForMsp(selectedAccount, url, existingFilters, mspAccountLoggedIn, accountsNames, accountsOrders);
      default:
        return false;
    }
  }
  return true;
}

function validateFiltersForBa(existingFilters: string[]): boolean {
  return existingFilters.every((element: string) => {
    const filterKeyValuePairs = element.split("=");
    const isValidFilterKeyValue = validateFilterKeyValuePairForBa(filterKeyValuePairs[0], filterKeyValuePairs[1]);
    if (!isValidFilterKeyValue) {
      return false;
    }
    return true;
  });
}

function validateFiltersForMsp(selectedAccount: IAccount | undefined, url: string, existingFilters: string[], mspAccountLoggedIn: IAccount, accountsNames: IAccount[], accountsOrders: IAccountOrders[]): boolean {
  let isValid = true;

  isValid = existingFilters.every((element: string) => {
    const filterKeyValuePairs = element.split("=");
    const isValidFilterKeyValue = validateFilterKeyValuePair(filterKeyValuePairs[0], filterKeyValuePairs[1]);
    if (!isValidFilterKeyValue) {
      return false;
    }
    return true;
  });

  if (isValid) {
    const filters = new UrlFilterExtractor(url, accountsOrders).extractFilters();
    isValid = validateFilterCompatibility(filters) && validateFilterOwnership(selectedAccount, filters, mspAccountLoggedIn, accountsNames) && validateProducts(existingFilters, filters, mspAccountLoggedIn, accountsOrders);
  }

  return isValid;
}

function validateFilterKeyValuePair(key: string, value: string): boolean {
  switch (key) {
    case FilterAccountsUrlOptions.hasErrors:
    case FilterAccountsUrlOptions.noProducts:
    case FilterAccountsUrlOptions.onlyM365Linked:
    case FilterAccountsUrlOptions.onlyM365Unlinked:
    case FilterAccountsUrlOptions.hasSmbLogins:
    case FilterAccountsUrlOptions.hasIbu:
      if (value !== "1") {
        return false;
      }
      break;
    case FilterAccountsUrlOptions.associatedLogin:
      if (!EmailValidationRule.RegularExpression.test(value)) {
        return false;
      }
      break;
    case FilterAccountsUrlOptions.csProdSkus:
    case FilterAccountsUrlOptions.bbsProdSkus:
    case FilterAccountsUrlOptions.essSkus:
    case FilterAccountsUrlOptions.accountName:
    case FilterAccountsUrlOptions.accountsOf:
      if (value === "") {
        return false;
      }
      break;
    default:
      return false;
  }
  return true;
}

function validateFilterKeyValuePairForBa(key: string, value: string): boolean {
  return key === FilterAccountsUrlOptions.accountName && value !== "";
}

function validateFilterCompatibility(filters: IAccountFilters): boolean {
  if (filters.onlyM365Linked && filters.onlyM365Unlinked) {
    return false;
  }
  if ((filters.allProducts || filters.hasErrors) && filters.noProducts) {
    return false;
  }
  if ((filters.essSkus || filters.csProdSkus || filters.bbsProdSkus) && filters.noProducts) {
    return false;
  }
  return true;
}

function validateFilterOwnership(selectedAccount: IAccount | undefined, filters: IAccountFilters, mspAccountLoggedIn: IAccount, accountsNames: IAccount[]): boolean {
  let isValid = false;
  if (filters.accountsOf) {
    const allAccounts = [mspAccountLoggedIn, ...accountsNames];
    const index = allAccounts.findIndex((x: IAccount) => x.id === Number(filters.accountsOf));
    if (index > -1) {
      if (allAccounts[index].type === MspType.Partner || allAccounts[index].type === MspType.Subpartner) {
        if (selectedAccount !== undefined) {
          isValid = selectedAccount.closestParentId === Number(filters.accountsOf);
        } else {
          isValid = true;
        }
      }
    }
  } else {
    isValid = true;
  }
  return isValid;
}

function validateProducts(existingFilters: string[], filters: IAccountFilters, mspAccountLoggedIn: IAccount, accountsOrders: IAccountOrders[]): boolean {
  if (filters.essSkus || filters.csProdSkus || filters.bbsProdSkus || filters.allProducts || filters.hasErrors || filters.noProducts) {
    let index = -1;
    if (filters.accountsOf) {
      index = accountsOrders.findIndex((x: IAccountOrders) => x.accountId === Number(filters.accountsOf));
    } else {
      index = accountsOrders.findIndex((x: IAccountOrders) => x.accountId === mspAccountLoggedIn.id);
    }
    if (index < 0) {
      return false;
    }
    if (accountsOrders[index].orders.length === 0) {
      return false;
    }
    return validateEssSkus(existingFilters, accountsOrders[index].orders) && validateProdSkus(filters, accountsOrders[index].orders, ProductFamily.CONTENT_SHIELD) && validateProdSkus(filters, accountsOrders[index].orders, ProductFamily.BACKUP_APPLIANCES);
  }
  return true;
}

function validateEssSkus(existingFilters: string[], orders: IOrderSlim[]): boolean {
  let isValid = true;
  const existingEssFilters = existingFilters.filter((x: string) => x.includes(FilterAccountsUrlOptions.essSkus));
  if (existingEssFilters.length > 0) {
    const filtersFromUrl = existingEssFilters
      .map((x: string) => x.split("=")[1].split("%2C").join(",")) //.replaceAll("%2C", ",")) //use replaceAll after node.js upgrade
      .map((x: string) => x.split(","))
      .flat();
    const ownedEssSkus = orders.filter((order: IOrderSlim) => order.familyName.toLowerCase().includes(ProductFamily.ESSENTIALS_SERIVICES.toLowerCase())).map(x => x.bundleSku);
    filtersFromUrl.forEach((x: string) => {
      let found = false;
      ownedEssSkus.forEach((y: string) => {
        if (y === x) {
          found = true;
        }
      });
      if (found === false) {
        isValid = false;
      }
    });
  }
  return isValid;
}

function validateProdSkus(filters: IAccountFilters, orders: IOrderSlim[], orderType: ProductFamily): boolean {
  let skusFromFilter: string | undefined = undefined;
  switch (orderType) {
    case ProductFamily.BACKUP_APPLIANCES:
      skusFromFilter = filters.bbsProdSkus;
      break;
    case ProductFamily.CONTENT_SHIELD:
      skusFromFilter = filters.csProdSkus;
      break;
    default:
      return false;
  }
  if (skusFromFilter && skusFromFilter.length > 0) {
    const ownedSkus = computeProdSkuFilterOptions(orders, orderType);
    if (ownedSkus) {
      const ownedSkusSorted = ownedSkus.split(",").sort();
      const filterSkusSorted = skusFromFilter.split("%2C").join(",").split(",").sort(); //use replaceAll after node.js upgrade
      if (JSON.stringify(ownedSkusSorted) !== JSON.stringify(filterSkusSorted)) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
}
