import { IAccountFilters } from "../../../models/IAccountFilters";
import { FilterAccountsUrlOptions } from "./UrlFilterParams";

export class URLFilterBuilder {
  private filters: IAccountFilters;
  private filterChildrenOfAccountId?: number;
  private directChildrenOnly?: boolean;
  private hasSubpartners?: boolean;

  constructor(filters: IAccountFilters, filterChildrenOfAccountId?: number, directChildrenOnly?: boolean, hasSubpartners?: boolean) {
    this.filters = filters;
    this.filterChildrenOfAccountId = filterChildrenOfAccountId;
    this.directChildrenOnly = directChildrenOnly;
    this.hasSubpartners = hasSubpartners;
  }

  BuildFilterUrl(): string {
    let filtersToUrl = "";

    filtersToUrl = this.addQueryParam(filtersToUrl, this.hasSubpartners && this.directChildrenOnly, FilterAccountsUrlOptions.accountsOf, this.filterChildrenOfAccountId?.toString()!);
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.name, FilterAccountsUrlOptions.accountName, this.filters.name!);
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.noProducts, FilterAccountsUrlOptions.noProducts, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.hasErrors, FilterAccountsUrlOptions.hasErrors, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.onlyM365Linked, FilterAccountsUrlOptions.onlyM365Linked, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.onlyM365Unlinked, FilterAccountsUrlOptions.onlyM365Unlinked, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.hasSmbLogins, FilterAccountsUrlOptions.hasSmbLogins, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.hasIbu, FilterAccountsUrlOptions.hasIbu, "1");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.associatedLogin, FilterAccountsUrlOptions.associatedLogin, this.filters.associatedLogin!);

    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.csProdSkus, FilterAccountsUrlOptions.csProdSkus, this.filters.csProdSkus + "");
    filtersToUrl = this.addQueryParam(filtersToUrl, this.filters.bbsProdSkus, FilterAccountsUrlOptions.bbsProdSkus, this.filters.bbsProdSkus + "");

    if (this.filters.essSkus) {
      filtersToUrl += "&" + FilterAccountsUrlOptions.essSkus + "=" + this.filters.essSkus?.map(item => item.sku).join(",");
    }

    return filtersToUrl;
  }

  addQueryParam(url: string, condition: boolean | string | undefined, paramName: string, paramValue: string) {
    if (condition) {
      url += `&${paramName}=${paramValue}`;
    }
    return url;
  }
}
